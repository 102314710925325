@use 'sass:map';

$default: (
    light: #f1f1f1,
    medium: #d3d3d3,
    regular: #aaa,
    dark: #656565,
    font: #212121,
    header: #669ddd,
);
$seniors: (
    light: #669edd,
    regular: #4178b7,
    dark: #376395,
    font: #294669,
    header: #669ddd,
);
$relatives: (
    light: #de75b3,
    regular: #c65096,
    dark: #a4487e,
    font: #712a54,
    header: #dd75b2,
);
$experts: (
    light: #ad94ca,
    regular: #8b77a2,
    dark: #7b6784,
    font: #54415d,
    header: #ad94cb,
);
$doctors: (
    light: #5ac4c8,
    regular: #3ea4a8,
    dark: #34878b,
    font: #225456,
    header: #5bc4c8,
);
$news: (
    light: #669edd,
    regular: #20528e,
    dark: #376395,
    font: #294669,
    header: #669ddd,
);

@mixin layout($property, $style) {
    @if map.has-key($default, $style) {
        #{$property}: map.get($default, $style);
    }

    .layout--seniors & {
        @if not map.has-key($seniors, $style) {
            #{$property}: $style;
        } @else {
            #{$property}: map.get($seniors, $style);
        }
    }

    .layout--relatives & {
        @if not map.has-key($relatives, $style) {
            #{$property}: $style;
        } @else {
            #{$property}: map.get($relatives, $style);
        }
    }

    .layout--experts & {
        @if not map.has-key($experts, $style) {
            #{$property}: $style;
        } @else {
            #{$property}: map.get($experts, $style);
        }
    }

    .layout--doctors & {
        @if not map.has-key($doctors, $style) {
            #{$property}: $style;
        } @else {
            #{$property}: map.get($doctors, $style);
        }
    }

    .layout--news & {
        @if not map.has-key($news, $style) {
            #{$property}: $style;
        } @else {
            #{$property}: map.get($news, $style);
        }
    }
}

@mixin modify($property, $style) {
    &--seniors {
        #{$property}: map.get($seniors, $style);
    }

    &--relatives {
        #{$property}: map.get($relatives, $style);
    }

    &--experts {
        #{$property}: map.get($experts, $style);
    }

    &--doctors {
        #{$property}: map.get($doctors, $style);
    }

    &--news {
        #{$property}: map.get($news, $style);
    }
}
