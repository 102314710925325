@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/icon';
@use '../global/media';

.section-news {
    clear: both;
    margin-top: 1px;
    border-top: 1px solid #b6b6b6;

    &__inside {
        padding: 50px 20px;
    }

    h1 {
        font-size: 26px;
        line-height: 32px;
    }

    h2 {
        margin-top: 2em;
        color: map.get(color.$news, font);
        font-size: 24px;
        line-height: 28px;
    }

    &__link {
        position: relative;
        display: block;
        height: 45px;
        margin: 35px 5px;
        padding-top: 9px;
        color: #fff;
        font-weight: map.get(font.$font-weight, medium);
        font-size: 13px;
        line-height: 31px;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        background: map.get(color.$news, regular);
        transition: padding-right 0.3s;

        @include icon.link(button, 9, after) {
            position: absolute;
            top: 50%;
            margin-top: -4.5px;
            margin-left: 10px;
            opacity: 0;
            transition: opacity 0.3s;
        }

        &:hover {
            padding-right: 40px;

            &::after {
                opacity: 1;
            }
        }
    }

    @include media.screen(tablet) {
        &__inside {
            max-width: 1100px;
            margin: 0 auto;
            padding-right: 35px;
            padding-left: 35px;
        }

        &__link {
            width: 200px;
            margin: 20px 0 30px;
        }
    }

    @include media.screen(desktop) {
        font-size: 24px;
        line-height: 42px;

        h1 {
            margin-bottom: 1.6em;
            font-size: 36px;
            line-height: 47px;
        }

        h2 {
            font-size: 30px;
            line-height: 47px;
        }

        &__inside {
            max-width: 1250px;
        }

        &__link {
            width: 250px;
            height: 61px;
            padding-top: 17px;
            font-size: 18px;
        }
    }
}
