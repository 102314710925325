@use 'sass:map';
@use 'sass:string';

@mixin screen($size) {
    $sizes: (
        tablet: 'min-width: 767px',
        tablet-only: 'min-width: 767px) and (max-width: 1264px',
        desktop: 'min-width: 1265px',
    );

    @media (#{string.unquote(map.get($sizes, $size))}) {
        @content;
    }
}
