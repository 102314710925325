@use 'sass:map';

@mixin topic($topic, $height, $style) {
    // horizontal offset
    $topics: (
        default: 40 * 0,
        alcohol: 40 * 0,
        drugs: 40 * 1,
        opiate: 40 * 2,
        help: 40 * 3,
    );

    // vertical offset
    $styles: (
        default: 45.8 * 0,
        white: 45.8 * 1,
        seniors: 45.8 * 2,
        experts: 45.8 * 3,
        relatives: 45.8 * 4,
        doctors: 45.8 * 5,
    );

    @if map.has-key($topics, $topic) {
        $topic: map.get($topics, $topic);
    } @else {
        $topic: map.get($topics, default);
    }

    @if map.has-key($styles, $style) {
        $style: map.get($styles, $style);
    } @else {
        $style: map.get($styles, default);
    }

    $width: calc(1 / 40 * (40 / 45.8 * $height));
    $height: calc(1 / 45.8 * $height);

    &::before {
        width: 40px * $width;
        height: 45.8px * $height;
        background: url('../../images/topic-sprite.svg') -1px * $topic * $width -1px * $style * $height no-repeat;
        background-size: 160px * $width 274.8px * $height;
        content: '';
        @content;
    }
}

@mixin topic-layout($topic, $height) {
    .layout--seniors & {
        @include topic($topic, $height, seniors) {
            @content;
        }
    }

    .layout--relatives & {
        @include topic($topic, $height, relatives) {
            @content;
        }
    }

    .layout--experts & {
        @include topic($topic, $height, experts) {
            @content;
        }
    }

    .layout--doctors & {
        @include topic($topic, $height, doctors) {
            @content;
        }
    }

    .layout--news & {
        @include topic($topic, $height, news) {
            @content;
        }
    }
}

@mixin link($style, $height: 9, $position: 'before') {
    // vertical offset
    $styles: (
        default: 9 * 0,
        button: 9 * 1,
        seniors: 9 * 2,
        experts: 9 * 3,
        relatives: 9 * 4,
        doctors: 9 * 5,
        news: 9 * 2,
    );

    @if map.has-key($styles, $style) {
        $style: map.get($styles, $style);
    } @else if $style != layout {
        $style: map.get($styles, default);
    }

    $factor: calc(1 / 9 * $height);

    &:#{$position} {
        @if $style == layout {
            background: url('../../images/link-sprite.svg') 0 -1px * map.get($styles, default) * $factor no-repeat;

            .layout--seniors & {
                background-position: 0 -1px * map.get($styles, seniors) * $factor;
                background-size: 39px * $factor 54px * $factor;
            }

            .layout--relatives & {
                background-position: 0 -1px * map.get($styles, relatives) * $factor;
                background-size: 39px * $factor 54px * $factor;
            }

            .layout--experts & {
                background-position: 0 -1px * map.get($styles, experts) * $factor;
                background-size: 39px * $factor 54px * $factor;
            }

            .layout--doctors & {
                background-position: 0 -1px * map.get($styles, doctors) * $factor;
                background-size: 39px * $factor 54px * $factor;
            }

            .layout--news & {
                background-position: 0 -1px * map.get($styles, news) * $factor;
                background-size: 39px * $factor 54px * $factor;
            }
        } @else {
            background: url('../../images/link-sprite.svg') left -1px * $style * $factor no-repeat;
        }

        width: 39px * $factor;
        height: 9px * $factor;
        background-size: 39px * $factor 54px * $factor;
        content: '';
        @content;
    }
}

@mixin download($style, $height: 24, $position: 'before') {
    // vertical offset
    $styles: (
        default: 24 * 0,
        seniors: 24 * 0,
        experts: 24 * 1,
        relatives: 24 * 2,
        doctors: 24 * 3,
        news: 24 * 0,
    );

    @if map.has-key($styles, $style) {
        $style: map.get($styles, $style);
    } @else if $style != layout {
        $style: map.get($styles, default);
    }

    $factor: calc(1 / 24 * $height);

    &:#{$position} {
        @if $style == layout {
            background: url('../../images/download-sprite.svg') left -1px * map.get($styles, default) * $factor no-repeat; // stylelint-disable-line

            .layout--seniors & {
                background-position: 0 -1px * map.get($styles, seniors) * $factor;
                background-size: 32px * $factor 96px * $factor;
            }

            .layout--relatives & {
                background-position: 0 -1px * map.get($styles, relatives) * $factor;
                background-size: 32px * $factor 96px * $factor;
            }

            .layout--experts & {
                background-position: 0 -1px * map.get($styles, experts) * $factor;
                background-size: 32px * $factor 96px * $factor;
            }

            .layout--doctors & {
                background-position: 0 -1px * map.get($styles, doctors) * $factor;
                background-size: 32px * $factor 96px * $factor;
            }

            .layout--news & {
                background-position: 0 -1px * map.get($styles, news) * $factor;
                background-size: 32px * $factor 96px * $factor;
            }
        } @else {
            background: url('../../images/download-sprite.svg') left -1px * $style * $factor no-repeat;
        }

        width: 32px * $factor;
        height: 24px * $factor;
        background-size: 32px * $factor 96px * $factor;
        content: '';
        @content;
    }
}

@mixin logo($height) {
    $styles: (
        seniors: 162 * 0,
        relatives: 162 * 1,
        experts: 162 * 2,
        doctors: 162 * 3,
        news: 162 * 4,
    );
    $widths: (
        de: 420,
        fr: 536,
        it: 608,
    );
    $languages: (
        de: 0,
        fr: map.get($widths, de),
        it: map.get($widths, de) + map.get($widths, fr),
    );
    $scale: calc(1 / 162 * $height);

    @each $language, $width in $widths {
        $width: $width * $scale;
        $x: map.get($languages, $language) * $scale;

        html[lang='#{$language}'] & {
            width: $width;
            height: $height;
            background-position: -1 * $x 0;
            background-size: 1564 * $scale 810 * $scale;
        }
    }

    @each $style, $y in $styles {
        @each $language, $width in $widths {
            $x: map.get($languages, $language) * $scale;

            html[lang='#{$language}'] .layout--#{$style} & {
                background-position: -1 * $x -1 * $y * $scale;
            }
        }
    }
}
