@use 'global/media';

@import 'sections/news';
@import 'elements/home-topic';

.section-main {
    padding-bottom: 40px;

    @include media.screen(tablet) {
        padding-bottom: 65px;
    }
}
