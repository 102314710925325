@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/icon';
@use '../global/media';

.home-topic {
    padding: 40px 20px 15px;
    color: #343434;
    text-align: center;

    &__headline {
        width: 180px;
        margin: 0 auto;
        padding-bottom: 15px;
        font-size: 24px;
        border-bottom: 2px solid;
        @include color.modify(border-color, regular);
    }

    &__text {
        margin: 20px 0;
    }

    &__link {
        position: relative;
        display: block;
        height: 45px;
        margin: 5px;
        padding-top: 9px;
        color: #fff;
        font-weight: map.get(font.$font-weight, medium);
        font-size: 13px;
        line-height: 31px;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        transition: padding-right 0.3s;
        @include color.modify(background, regular);

        @include icon.link(button, 9, after) {
            position: absolute;
            top: 50%;
            margin-top: -4.5px;
            margin-left: 10px;
            opacity: 0;
            transition: opacity 0.3s;
        }

        &:hover {
            padding-right: 40px;

            &::after {
                opacity: 1;
            }
        }
    }

    @include media.screen(tablet) {
        float: left;
        width: 50%;
        padding: 50px 50px 15px;
        text-align: left;

        &__container {
            position: relative;

            &::before {
                position: absolute;
                top: 50px;
                bottom: 15px;
                left: 50%;
                width: 1px;
                background: #b7b7b7;
                content: '';
            }

            &::after {
                display: table;
                clear: both;
                content: '';
            }
        }

        &__headline {
            margin: 0;
        }

        &__link {
            width: 200px;
            margin: 0;
        }

        @supports (column-count: 2) {
            &__container {
                column-count: 2;
                column-gap: 0;
            }

            float: none;
            width: auto;
        }
    }

    @include media.screen(desktop) {
        padding: 80px 0 15px;

        &__container {
            max-width: 1180px;
            margin: 0 auto;

            &::before {
                top: 80px;
            }
        }

        &:nth-child(odd) {
            padding-right: 100px;
        }

        &:nth-child(even) {
            padding-left: 100px;
        }

        &__headline {
            width: 240px;
            padding-bottom: 25px;
            font-size: 36px;
            line-height: 37px;
            border-width: 4px;
        }

        &__text {
            margin: 30px 0;
            font-weight: map.get(font.$font-weight, xlight);
            font-size: 24px;
            line-height: 38px;
        }

        &__link {
            width: 250px;
            height: 61px;
            padding-top: 17px;
            font-size: 18px;
        }

        @supports (column-count: 2) {
            &:nth-child(odd) {
                padding-right: 0;
            }

            &:nth-child(even) {
                padding-left: 0;
            }

            &__container {
                column-gap: 200px;
            }
        }
    }
}
